import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Img from 'gatsby-image'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import FeaturedServices from '../components/shortcodes/FeaturedServices'
import ClientLogos from '../components/ClientLogos'
import Anchor from '../components/shortcodes/Anchor'
import Button from '../components/shortcodes/Button'
import HomeStyles from '../styles/Home.module.sass'

/**
 * Display the index page template
 *
 * @param {string} title
 * @param {string} subtitle
 * @param {object} image
 */
const IndexTemplate = ({
  title,
  h2Title,
  pageIdentifier,
  seoTitle,
  seoDescription,
  image,
  introTitle,
  introText,
  introImage,
  subtitle,
  body,
  disableMdx,
  imageAnchor,
  imageAlt,
}) => {
  return (
    <Layout
      className={`is-page-${pageIdentifier}`}
      seoTitle={seoTitle}
      seoDescription={seoDescription}
    >
      <Hero
        title={title}
        special="true"
        image={image}
        h2Title={h2Title}
        anchor={imageAnchor}
        imageAlt={imageAlt || title}
        subtitle={subtitle}
      />

      <div className={`container content is-semi-narrow is-clearfix ${HomeStyles.intro || ''}`}>
        <div className="columns">
          <div className="column">
            <h2 className="title is-4">{introTitle}</h2>
            <p>{introText}</p>
            <p><Button class="is-secondary" text="Make an Enquiry" /></p>
          </div>
          <div className="column">
            <Img
              fluid={introImage.childImageSharp.fluid}
              alt={title || ''}
              title={title || ''}
            />
          </div>
        </div>
      </div>

      <div
        className={`content ${HomeStyles.featuredServicesHolder || ''}`}
      >
        <Anchor id="shopfitting-services" />
        
        <FeaturedServices />

        <ClientLogos />

        <span className="shadow-fold"></span>
      </div>

      <div className="container content content-body is-narrow">
        {/* Markdown fallback for CMS Previews */}
        {disableMdx === true || disableMdx === 'true' ? (
          body
        ) : (
          <MDXRenderer>{body}</MDXRenderer>
        )}
      </div>
    </Layout>
  )
}

export { IndexTemplate }

/**
 * Create Netlify CMS-compatible Preview Component
 */
const IndexPage = () => {
  const data = useStaticQuery(post)

  // Split imageAnchor classes when multiple
  const formattedImageAnchor = data.mdx.frontmatter.imageAnchor
    ? data.mdx.frontmatter.imageAnchor.toString().replace(',', ' ')
    : ''

  return (
    <IndexTemplate
      title={data.mdx.frontmatter.title}
      h2Title={data.mdx.frontmatter.h2Title}
      pageIdentifier={data.mdx.fields.pageIdentifier}
      seoTitle={data.mdx.frontmatter.seoTitle}
      seoDescription={data.mdx.frontmatter.seoDescription}
      introTitle={data.mdx.frontmatter.introTitle}
      introText={data.mdx.frontmatter.introText}
      introImage={data.mdx.fields.introImage}
      image={data.mdx.fields.image}
      imageAlt={data.mdx.frontmatter.imageAlt}
      aboveTitle={data.mdx.frontmatter.aboveTitle}
      subtitle={data.mdx.frontmatter.subtitle}
      body={data.mdx.body}
      imageAnchor={formattedImageAnchor || ''}
    />
  )
}

export default IndexPage

export const post = graphql`
  {
    mdx(frontmatter: { templateKey: { eq: "index" } }) {
      body
      rawBody
      frontmatter {
        title
        introTitle
        introText
        subtitle
        seoTitle
        seoDescription
        h2Title
        imageAnchor
        imageAlt
      }
      fields {
        pageIdentifier
        image {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        introImage {
          childImageSharp {
            fluid(maxWidth: 400, maxHeight: 240) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`