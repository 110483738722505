import React from 'react'
import CTA from './shortcodes/CTA'
import ClientLogosStyles from '../styles/ClientLogos.module.sass'

/**
 * Display a section with Client Logos
 */
const ClientLogos = () => {
  return (
    <div className={ClientLogosStyles.clientLogosContainer || ''}>
      <strong>We have done shop fitouts across Ireland for clients such as:</strong>
      <ul className={ClientLogosStyles.clientLogos || ''}>
        <li className={ClientLogosStyles.singleLogo || ''}>
          <img src="/media/client-logos/aib-logo.svg" alt="AIB Logo" width="61" height="61" />
        </li>
        <li className={ClientLogosStyles.singleLogo || ''}>
          <img src="/media/client-logos/ikea-logo.svg" alt="IKEA Logo" width="104" height="37" />
        </li>
        <li className={ClientLogosStyles.singleLogo || ''}>
          <img src="/media/client-logos/amazon-logo.svg" alt="Amazon Logo" width="107" height="33" />
        </li>
        <li className={ClientLogosStyles.singleLogo || ''}>
          <img src="/media/client-logos/temple-bar-logo.svg" alt="Temple Bar Logo" width="107" height="55" />
        </li>
        <li className={ClientLogosStyles.singleLogo || ''}>
          <img src="/media/client-logos/marks-and-spencer-logo.svg" alt="Marks and Spencer Logo" width="76" height="46" />
        </li>
        <li className={ClientLogosStyles.singleLogo || ''}>
          <img src="/media/client-logos/heineken-logo.svg" alt="Heineken Logo" width="115" height="49" />
        </li>
        <li className={ClientLogosStyles.singleLogo || ''}>
          <img src="/media/client-logos/chatime-logo.svg" alt="Chatime Logo" width="64" height="61" />
        </li>
        <li className={ClientLogosStyles.singleLogo || ''}>
          <img src="/media/client-logos/woodruff-restaurant-logo.svg" alt="Woodruff Restaurant Logo" width="117" height="22" />
        </li>
        <li className={ClientLogosStyles.singleLogo || ''}>
          <img src="/media/client-logos/griffith-college-logo.png" alt="Griffith College Logo" width="147" height="45" />
        </li>
      </ul>
      <CTA />
    </div>
  )
}

export default ClientLogos